
import { Component, Mixins } from 'vue-property-decorator';
import { UsePageHeaderMixin } from '@/mixins/use-page-header.mixin';
import { UseCurrentLocaleMixin } from '@/mixins/use-current-locale.mixin';
import { namespace } from 'vuex-class';
import { User } from '@/models/users/user.class';
import RoomType from '@/models/facilities/room-type.class';
import { FetchAllParams } from '@/models/shared/fetch-all-params.interface';
import { translateApi } from '@/utils/translate-api';
import env from '../../env.config';
import { createRouteWithLocaleParam } from '@/utils/create-route-with-locale-param';

const authModule = namespace('auth');
const roomTypesModule = namespace('roomTypes');
const locationsModule = namespace('locations');

interface LocationLocal extends Location {
  _id: string;
}

@Component({
  components: {},
  filters: { translateApi },
})
export default class Facilities extends Mixins(UsePageHeaderMixin, UseCurrentLocaleMixin) {
  @authModule.Getter('authenticatedUser')
  user!: User;

  @roomTypesModule.Action('fetch')
  fetchRoomTypes!: () => RoomType[];

  @roomTypesModule.Getter('roomTypes')
  roomTypes!: RoomType[];

  @locationsModule.Action('FETCH_UNPAGINATED')
  fetchLocations!: (payload?: FetchAllParams) => Location[];

  @locationsModule.Getter('ALL')
  locations!: LocationLocal[];

  get locale() {
    return this.$i18n.locale;
  }

  get headerHtml() {
    return `
      <div>
        <h2 class="text-white">${this.$t('facilities.roomTypes.title')}</h2>
        <p class="text-white">${this.$t('facilities.roomTypes.subtitle')}</p>
      </div>
    `;
  }

  getImageUrl(path) {
    return path && `${env.OKF_API_ROOT}/${path.replace('uploads/', '')}`;
  }

  openFacilitiesBooking(roomTypeId) {
    const route = createRouteWithLocaleParam('facilitiesBooking', this.localeCode, {
      roomTypeId: roomTypeId,
    });
    this.$router.push(route);
  }

  getLocationsNames(locationsIds) {
    if (this.locations.length) {
      const locations = locationsIds.map(locationId =>
        this.locations.find(location => location._id === locationId),
      );

      return (
        locations &&
        locations.length &&
        locations.map(location => location.name[this.$i18n.locale]).join(', ')
      );
    }

    return '-';
  }

  async created() {
    await this.fetchRoomTypes();
    await this.fetchLocations();
  }
}
